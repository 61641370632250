<template>
  <div>
      <div class="px-2">

        <div class="form-group">
          <div class="controls">
            <label>Current Status</label>
            <div style="margin-top: .5rem">
              <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                <div>
                  <input v-model="sliderStatus" type="checkbox" class="custom-control-input" checked id="sliderStatus">
                  <label class="custom-control-label mr-1" for="sliderStatus"></label>
                </div>
                <span class="font-medium-1">{{ sliderStatus ? "Banner" : "Slider" }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="w-100" style="text-align: center">
            <h2>Banner Details</h2>
          </div>
          <div class="d-flex w-100 align-items-end mb-3">

            <div class="w-100 col-md-4">
              <label>Info Link</label>
              <input v-model="currentSetting.value.banner_details.info_link" type="text" class="form-control" id="bannerInfoLink" placeholder="Info Link">
            </div>

            <div class="w-100 col-md-6">
              <label>Image Url</label>
              <input v-model="currentSetting.value.banner_details.image_url" type="text" class="form-control" id="bannerImageUrl" placeholder="Image Url">
            </div>

            <div class="w-100  text-center col-md-2">
            <label>Current Image </label>
              <img class="img-fluid" style="height: 80px" :src="currentSetting.value.banner_details.image_url" alt="">
            </div>

          </div>

        </div>

        <div class="w-100" style="text-align: center">
          <h2>Slider Details</h2>
        </div>
        <div class="w-100 mr-1">
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary btn-sm" @click="addNewSlider()">Add new Slider</button>
          </div>
        </div>

        <div class="" v-for="(singleSlider,index) in currentSetting.value?.slider_details ?? []" :key="index">
          <div class="row">

            <div class="col-md-4">
              <label>Info Link</label>
              <input v-model="singleSlider.info_link" type="text" class="form-control" id="infoLink" placeholder="Info Link">
            </div>

            <div class="col-md-4">
              <label>Image Url</label>
              <input v-model="singleSlider.image_url" type="text" class="form-control" id="imageUrl" placeholder="Image Url">
            </div>

            <div class="w-100  text-center col-md-2">
              <label>Current Image </label>

              <img class="img-fluid" style="height: 80px" :src="singleSlider.image_url" alt="">
            </div>

            <div class="col-md-2 mt-1 d-flex flex-column ">
              <label style="visibility: hidden">label</label>
              <button @click="removeSlider(singleSlider.id)" class="btn"><i class='bx bx-x'></i></button>
            </div>

          </div>
        </div>


        <div class="d-flex w-100 align-items-center justify-content-end px-2">
          <div>
            <button :disabled="isUpdateButtonDisable" @click="updateSettingsHandler" class="btn btn-primary " type="submit">Update</button>
          </div>

        </div>
      </div>

    </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "SettingDefaultWebsiteHeaderBannerSlider",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['defaultWebsiteHeaderBannerSlider'],
  data() {
    return {
      sliderStatus: false,
      currentSetting: this.defaultWebsiteHeaderBannerSlider,
    };
  },
  computed: {
    isUpdateButtonDisable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null || !this.can('setting-default-update');
    }
  },
  watch: {
    sliderStatus(sliderStatus) {
      this.currentSetting.value.slider_status = sliderStatus ? 1 : 0;
    }
  },


  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    async updateSettingsHandler() {
      await this.updateSettings();
    },
    removeSlider(sliderId) {
      if (this.currentSetting.value.slider_details.length === 1) {
        this.showToastMessage({
          type: 'error',
          message: `Can't delete`
        });
        return;
      }
      this.currentSetting.value.slider_details = this.currentSetting.value.slider_details.filter((singleSlider) => singleSlider.id !== sliderId);
    },
    addNewSlider() {

      this.currentSetting.value.slider_details.push({
        id: this.currentSetting.value.slider_details.length > 0 ? (this.currentSetting.value.slider_details[this.currentSetting.value.slider_details.length - 1].id + 1) : 1,
        image_url: "",
        info_link: "",
      });

    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      dataObj.data.value.slider_details = this.currentSetting.value.slider_details.map((item) => {
        const {image_url, info_link} = item;
        return {
          image_url: image_url,
          info_link: info_link
        }
      });
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Website header banner slider updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
  mounted() {
    this.sliderStatus = this.defaultWebsiteHeaderBannerSlider.value?.slider_status ? (this.defaultWebsiteHeaderBannerSlider.value?.slider_status === 1 ? true : false) : false;
    this.currentSetting.value.slider_details = this.currentSetting.value.slider_details.map((singleSlider, index) => ({id: index + 1, ...singleSlider}))
  }
}
</script>

<style scoped>

</style>