
<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
      <div class="website-setting-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
          <h4 class="">Default Website/List</h4>
        </div>

            <div class="accordion" id="websiteAccordion">

              <div class="card">
                <div class="card-header" id="settingDefaultWebsiteUrlHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultWebsiteUrlCollapse" aria-expanded="false" aria-controls="collapseWebsiteUrl">

                    <span class="text-capitalize website-setting-title">Website Url</span>

                  </button>
                </div>
                <div id="settingDefaultWebsiteUrlCollapse" class="collapse" aria-labelledby="settingDefaultWebsiteUrlHeading" data-parent="#websiteAccordion">
                  <div class="card-body">
                    <SettingDefaultWebsiteUrl :default-website-url="this.defaultWebsiteUrl"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultWebsiteCustomerPortalLoginUrlHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultWebsiteCustomerPortalLoginUrlCollapse" aria-expanded="false" aria-controls="collapseWebsiteCustomerPortalLoginUrl">

                    <span class="text-capitalize website-setting-title"> Website Customer Portal Login Url</span>

                  </button>
                </div>
                <div id="settingDefaultWebsiteCustomerPortalLoginUrlCollapse" class="collapse" aria-labelledby="settingDefaultWebsiteCustomerPortalLoginUrlHeading" data-parent="#websiteAccordion">
                  <div class="card-body">
                    <SettingDefaultWebsiteCustomerPortalLoginUrl :default-website-customer-portal-login-url="this.defaultWebsiteCustomerPortalLoginUrl"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultWebsiteAboutUsUrlHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultWebsiteAboutUsUrlCollapse" aria-expanded="false" aria-controls="collapseDefaultWebsiteAboutUsUrl">

                    <span class="text-capitalize website-setting-title">Website About Us Url</span>

                  </button>
                </div>
                <div id="settingDefaultWebsiteAboutUsUrlCollapse" class="collapse" aria-labelledby="settingDefaultWebsiteAboutUsUrlHeading" data-parent="#websiteAccordion">
                  <div class="card-body">
                    <SettingDefaultWebsiteAboutUsUrl :default-website-about-us-url="this.defaultWebsiteAboutUsUrl"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultWebsiteTermsAndConditionsUrlHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultWebsiteTermsAndConditionsUrlCollapse" aria-expanded="false" aria-controls="collapseDefaultWebsiteTermsAndConditionsUrl">

                    <span class="text-capitalize website-setting-title">Website Terms And Conditions Url</span>

                  </button>
                </div>
                <div id="settingDefaultWebsiteTermsAndConditionsUrlCollapse" class="collapse" aria-labelledby="settingDefaultWebsiteTermsAndConditionsUrlHeading" data-parent="#websiteAccordion">
                  <div class="card-body">
                    <SettingDefaultWebsiteTermsAndConditionsUrl :default-website-terms-and-conditions-url="this.defaultWebsiteTermsAndConditionsUrl"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultWebsitePrivacyPolicyUrlHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultWebsitePrivacyPolicyUrlCollapse" aria-expanded="false" aria-controls="collapseDefaultWebsitePrivacyPolicyUrl">

                    <span class="text-capitalize website-setting-title">Website Privacy Policy Url</span>

                  </button>
                </div>
                <div id="settingDefaultWebsitePrivacyPolicyUrlCollapse" class="collapse" aria-labelledby="settingDefaultWebsitePrivacyPolicyUrlHeading" data-parent="#websiteAccordion">
                  <div class="card-body">
                    <SettingDefaultWebsitePrivacyPolicyUrl :default-website-privacy-policy-url="this.defaultWebsitePrivacyPolicyUrl"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultWebsiteMedicalServicePageUrlHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultWebsiteMedicalServicePageUrlCollapse" aria-expanded="false" aria-controls="collapseDefaultWebsiteMedicalServicePageUrl">

                    <span class="text-capitalize website-setting-title"> Website Medical Service Page Url</span>

                  </button>
                </div>
                <div id="settingDefaultWebsiteMedicalServicePageUrlCollapse" class="collapse" aria-labelledby="settingDefaultWebsiteMedicalServicePageUrlHeading" data-parent="#websiteAccordion">
                  <div class="card-body">
                    <SettingDefaultWebsiteMedicalServicePageUrl :default-website-medical-service-page-url="this.defaultWebsiteMedicalServicePageUrl"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultWebsiteNDISPageUrlHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultWebsiteNDISPageUrlCollapse" aria-expanded="false" aria-controls="collapseDefaultWebsiteNDISPageUrl">

                    <span class="text-capitalize website-setting-title"> Website NDIS Page Url</span>

                  </button>
                </div>
                <div id="settingDefaultWebsiteNDISPageUrlCollapse" class="collapse" aria-labelledby="settingDefaultWebsiteNDISPageUrlHeading" data-parent="#websiteAccordion">
                  <div class="card-body">
                    <SettingDefaultWebsiteNDISPageUrl :default-website-ndis-page-url="this.defaultWebsiteNdisPageUrl"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultWebsiteFaqUrlHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultWebsiteFaqUrlCollapse" aria-expanded="false" aria-controls="collapseDefaultWebsiteFaqUrl">

                    <span class="text-capitalize website-setting-title"> Website Faq Url</span>

                  </button>
                </div>
                <div id="settingDefaultWebsiteFaqUrlCollapse" class="collapse" aria-labelledby="settingDefaultWebsiteFaqUrlHeading" data-parent="#websiteAccordion">
                  <div class="card-body">
                    <SettingDefaultWebsiteFaqUrl :default-website-faq-url="this.defaultWebsiteFaqUrl"/>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="settingDefaultWebsiteLogoRegularHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultWebsiteLogoRegularCollapse" aria-expanded="false" aria-controls="collapseWebsiteLogoRegular">

                    <span class="text-capitalize website-setting-title">Website Logo Regular</span>

                  </button>
                </div>
                <div id="settingDefaultWebsiteLogoRegularCollapse" class="collapse" aria-labelledby="settingDefaultWebsiteLogoRegularHeading" data-parent="#websiteAccordion">
                  <div class="card-body">
                    <SettingDefaultWebsiteLogoRegular :default-website-logo-regular="this.defaultWebsiteLogoRegular"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultWebsiteLogoLiteHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultWebsiteLogoLiteCollapse" aria-expanded="false" aria-controls="collapseWebsiteLogoLite">

                    <span class="text-capitalize website-setting-title">Website Logo Lite</span>

                  </button>
                </div>
                <div id="settingDefaultWebsiteLogoLiteCollapse" class="collapse" aria-labelledby="settingDefaultWebsiteLogoLiteHeading" data-parent="#websiteAccordion">
                  <div class="card-body">
                    <SettingDefaultWebsiteLogoLite :default-website-logo-lite="this.defaultWebsiteLogoLite"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultWebsiteFaviconHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultWebsiteFaviconCollapse" aria-expanded="false" aria-controls="collapseWebsiteFavicon">

                    <span class="text-capitalize website-setting-title">Website Favicon</span>

                  </button>
                </div>
                <div id="settingDefaultWebsiteFaviconCollapse" class="collapse" aria-labelledby="settingDefaultWebsiteFaviconHeading" data-parent="#websiteAccordion">
                  <div class="card-body">
                    <SettingDefaultWebsiteFavicon :default-website-favicon="this.defaultWebsiteFavicon"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultWebsiteHeaderBannerSliderHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultWebsiteHeaderBannerSliderCollapse" aria-expanded="false" aria-controls="collapseDefaultWebsiteHeaderBannerSlider">

                    <span class="text-capitalize website-setting-title">Website Header Banner Slider</span>

                  </button>
                </div>
                <div id="settingDefaultWebsiteHeaderBannerSliderCollapse" class="collapse" aria-labelledby="settingDefaultWebsiteHeaderBannerSliderHeading" data-parent="#websiteAccordion">
                  <div class="card-body">
                    <SettingDefaultWebsiteHeaderBannerSlider v-if="Object.keys(this.defaultWebsiteHeaderBannerSlider?.value ?? {}).length > 0" :default-website-header-banner-slider="this.defaultWebsiteHeaderBannerSlider"/>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

    </template>
  </AppLayout>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from "@/layouts/backEnd/AppLayout";
import {mapActions, mapGetters} from "vuex";
import SettingDefaultWebsiteLogoRegular from "@/views/backEnd/settings/default/websites/includes/SettingDefaultWebsiteLogoRegular";
import SettingDefaultWebsiteLogoLite from "@/views/backEnd/settings/default/websites/includes/SettingDefaultWebsiteLogoLite";
import SettingDefaultWebsiteFavicon from "@/views/backEnd/settings/default/websites/includes/SettingDefaultWebsiteFavicon";
import SettingDefaultWebsiteUrl from "@/views/backEnd/settings/default/websites/includes/SettingDefaultWebsiteUrl";
import SettingDefaultWebsiteCustomerPortalLoginUrl from "@/views/backEnd/settings/default/websites/includes/SettingDefaultWebsiteCustomerPortalLoginUrl";
import SettingDefaultWebsiteMedicalServicePageUrl from "@/views/backEnd/settings/default/websites/includes/SettingDefaultWebsiteMedicalServicePageUrl";
import SettingDefaultWebsiteNDISPageUrl from "@/views/backEnd/settings/default/websites/includes/SettingDefaultWebsiteNDISPageUrl";
import SettingDefaultWebsiteFaqUrl from "@/views/backEnd/settings/default/websites/includes/SettingDefaultWebsiteFaqUrl";
import SettingDefaultWebsiteAboutUsUrl from "@/views/backEnd/settings/default/websites/includes/SettingDefaultWebsiteAboutUsUrl";
import SettingDefaultWebsiteTermsAndConditionsUrl from "@/views/backEnd/settings/default/websites/includes/SettingDefaultWebsiteTermsAndConditionsUrl";
import SettingDefaultWebsitePrivacyPolicyUrl from "@/views/backEnd/settings/default/websites/includes/SettingDefaultWebsitePrivacyPolicyUrl";
import SettingDefaultWebsiteHeaderBannerSlider from "@/views/backEnd/settings/default/websites/includes/SettingDefaultWebsiteHeaderBannerSlider";

export default {
  name: "SettingDefaultWebsite.vue",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
    SettingDefaultWebsiteLogoRegular,
    SettingDefaultWebsiteLogoLite,
    SettingDefaultWebsiteFavicon,
    SettingDefaultWebsiteUrl,
    SettingDefaultWebsiteCustomerPortalLoginUrl,
    SettingDefaultWebsiteMedicalServicePageUrl,
    SettingDefaultWebsiteNDISPageUrl,
    SettingDefaultWebsiteFaqUrl,
    SettingDefaultWebsiteAboutUsUrl,
    SettingDefaultWebsiteTermsAndConditionsUrl,
    SettingDefaultWebsitePrivacyPolicyUrl,
    SettingDefaultWebsiteHeaderBannerSlider,

  },
  data() {
    return {
      getSettingsParams: {
        type: ["default"],
        key: [
          "default_website_url",
          "default_website_customer_portal_login_url",
          "default_website_privacy_policy_url",
          "default_website_terms_and_conditions_url",
          "default_website_about_us_url",
          "default_website_medical_service_page_url",
          "default_website_ndis_page_url",
          "default_website_faq_url",
          "default_website_logo_regular",
          "default_website_logo_lite",
          "default_website_favicon",
          "default_website_header_banner_slider ",

        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      defaultWebsiteUrl: 'appSettings/settingDefaultWebsiteUrl',
      defaultWebsiteCustomerPortalLoginUrl: 'appSettings/settingDefaultWebsiteCustomerPortalLoginUrl',
      defaultWebsitePrivacyPolicyUrl: 'appSettings/settingDefaultWebsitePrivacyPolicyUrl',
      defaultWebsiteTermsAndConditionsUrl: 'appSettings/settingDefaultWebsiteTermsAndConditionsUrl',
      defaultWebsiteAboutUsUrl: 'appSettings/settingDefaultWebsiteAboutUsUrl',
      defaultWebsiteMedicalServicePageUrl: 'appSettings/settingDefaultWebsiteMedicalServicePageUrl',
      defaultWebsiteNdisPageUrl: 'appSettings/settingDefaultWebsiteNDISPageUrl',
      defaultWebsiteFaqUrl: 'appSettings/settingDefaultWebsiteFAQUrl',
      defaultCompanySocialLinkFacebook: 'appSettings/settingDefaultCompanySocialLinkFacebook',
      defaultCompanySocialLinkInstagram: 'appSettings/settingDefaultCompanySocialLinkInstagram',
      defaultCompanySocialLinkLinkedin: 'appSettings/settingDefaultCompanySocialLinkLinkedin',
      defaultCompanySocialLinkYoutube: 'appSettings/settingDefaultCompanySocialLinkYoutube',


      defaultWebsiteLogoRegular: 'appSettings/settingDefaultWebsiteLogoRegular',
      defaultWebsiteLogoLite: 'appSettings/settingDefaultWebsiteLogoLite',
      defaultWebsiteFavicon: 'appSettings/settingDefaultWebsiteFavicon',
      defaultWebsiteHeaderBannerSlider: 'appSettings/settingDefaultWebsiteHeaderBannerSlider',
    }),
  },
  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>


<style>
.website-setting-wrapper .accordion .card {
  overflow: visible !important;
}

.website-setting-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
